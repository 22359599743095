let stateList = [ /*56 US states and territories */
  { stateAbbreviation: "AL", stateFullName: "Alabama" },
  { stateAbbreviation: "AK", stateFullName: "Alaska" },
  { stateAbbreviation: "AZ", stateFullName: "Arizona" },
  { stateAbbreviation: "AR", stateFullName: "Arkansas" },
  { stateAbbreviation: "CA", stateFullName: "California" },
  { stateAbbreviation: "CO", stateFullName: "Colorado" },
  { stateAbbreviation: "CT", stateFullName: "Connecticut" },
  { stateAbbreviation: "DE", stateFullName: "Delaware" },
  { stateAbbreviation: "FL", stateFullName: "Florida" },
  { stateAbbreviation: "GA", stateFullName: "Georgia" },
  { stateAbbreviation: "HI", stateFullName: "Hawaii" },
  { stateAbbreviation: "ID", stateFullName: "Idaho" },
  { stateAbbreviation: "IL", stateFullName: "Illinois" },
  { stateAbbreviation: "IN", stateFullName: "Indiana" },
  { stateAbbreviation: "IA", stateFullName: "Iowa" },
  { stateAbbreviation: "KS", stateFullName: "Kansas" },
  { stateAbbreviation: "KY", stateFullName: "Kentucky" },
  { stateAbbreviation: "LA", stateFullName: "Louisiana" },
  { stateAbbreviation: "ME", stateFullName: "Maine" },
  { stateAbbreviation: "MD", stateFullName: "Maryland" },
  { stateAbbreviation: "MA", stateFullName: "Massachusetts" },
  { stateAbbreviation: "MI", stateFullName: "Michigan" },
  { stateAbbreviation: "MN", stateFullName: "Minnesota" },
  { stateAbbreviation: "MS", stateFullName: "Mississippi" },
  { stateAbbreviation: "MO", stateFullName: "Missouri" },
  { stateAbbreviation: "MT", stateFullName: "Montana" },
  { stateAbbreviation: "NE", stateFullName: "Nebraska" },
  { stateAbbreviation: "NV", stateFullName: "Nevada" },
  { stateAbbreviation: "NH", stateFullName: "New Hampshire" },
  { stateAbbreviation: "NJ", stateFullName: "New Jersey" },
  { stateAbbreviation: "NM", stateFullName: "New Mexico" },
  { stateAbbreviation: "NY", stateFullName: "New York" },
  { stateAbbreviation: "NC", stateFullName: "North Carolina" },
  { stateAbbreviation: "ND", stateFullName: "North Dakota" },
  { stateAbbreviation: "OH", stateFullName: "Ohio" },
  { stateAbbreviation: "OK", stateFullName: "Oklahoma" },
  { stateAbbreviation: "OR", stateFullName: "Oregon" },
  { stateAbbreviation: "PA", stateFullName: "Pennsylvania" },
  { stateAbbreviation: "RI", stateFullName: "Rhode Island" },
  { stateAbbreviation: "SC", stateFullName: "South Carolina" },
  { stateAbbreviation: "SD", stateFullName: "South Dakota" },
  { stateAbbreviation: "TN", stateFullName: "Tennessee" },
  { stateAbbreviation: "TX", stateFullName: "Texas" },
  { stateAbbreviation: "UT", stateFullName: "Utah" },
  { stateAbbreviation: "VT", stateFullName: "Vermont" },
  { stateAbbreviation: "VA", stateFullName: "Virginia" },
  { stateAbbreviation: "WA", stateFullName: "Washington" },
  { stateAbbreviation: "WV", stateFullName: "West Virginia" },
  { stateAbbreviation: "WI", stateFullName: "Wisconsin" },
  { stateAbbreviation: "WY", stateFullName: "Wyoming" },
  { stateAbbreviation: "AS", stateFullName: "American Samoa" },
  { stateAbbreviation: "DC", stateFullName: "District Of Columbia" },
  { stateAbbreviation: "GU", stateFullName: "Guam" },
  { stateAbbreviation: "MP", stateFullName: "Norther Mariana Islands" },
  { stateAbbreviation: "PR", stateFullName: "Puerto Rico" },
  { stateAbbreviation: "VI", stateFullName: "Virgin Islands" },
];

export default stateList;
